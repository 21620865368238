import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReactiveFormsModule } from '@angular/forms';
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ErrorComponent } from './pages/error/error.component';
import { HeaderComponent } from './components/header/header.component';
import { NavBarComponent } from './components/header/nav-bar.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoadingComponent } from './components/loading/loading.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { environment as env } from '../environments/environment';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ImprovementActionsComponent } from './components/improvement-actions/improvement-actions.component';
import { TrainingRecommendationsComponent } from './components/training-recommendations/training-recommendations.component';
import { AssessmentOverviewComponent } from './components/assessment-overview/assessment-overview.component';
import { AssessmentRadarComponent } from './components/assessment-radar/assessment-radar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SidebarAreasNavComponent } from './components/sidebar/sidebar-areas-nav.component';
import { SidebarNewAssessmentComponent } from './components/sidebar/sidebar-new-assessment.component';
import { ModuleMaturityComponent } from './components/module-maturity/module-maturity.component';
import { ModuleImprovementsComponent } from './components/module-improvements/module-improvements.component';
import { RecentAssessmentsComponent } from './components/recent-assessments/recent-assessments.component';
import { AssessmentComponent } from './components/assessment/assessment.component';
import { QuestionComponent } from './components/question/question.component';
import { ReviewComponent } from './components/review/review.component';
import { ResultsComponent } from './components/results/results.component';
import { SidebarResultsNavComponent } from './components/sidebar/sidebar-results-nav.component';
import { LoginComponent } from './pages/login/login.component';
import { AdminComponent } from './pages/admin/admin.component';
import { InviteUserComponent } from './forms/invite-user/invite-user.component';
import { EditUserComponent } from './forms/edit-user/edit-user.component';
import { SidebarInfoComponent } from './components/sidebar/sidebar-info.component';
import { MaintenanceModeComponent } from './pages/maintenance-mode/maintenance-mode.component';
import { QuestionPickerComponent } from './components/question-picker/question-picker.component';
import { PadLevelPipe } from './components/question-picker/question-picker.component';
import { EditMarketComponent } from './forms/edit-market/edit-market.component';
import { EditRegionComponent } from './forms/edit-region/edit-region.component';
import { EditCategoryComponent } from './forms/edit-category/edit-category.component';
import { EditOrganisationComponent } from './forms/edit-organisation/edit-organisation.component';
import { NumberOrBlankPipe } from './pipes';

@NgModule({
  declarations: [
    AppComponent,
    ProfileComponent,
    HeaderComponent,
    NavBarComponent,
    FooterComponent,
    LoadingComponent,
    ErrorComponent,
    DashboardComponent,
    ImprovementActionsComponent,
    TrainingRecommendationsComponent,
    AssessmentOverviewComponent,
    AssessmentRadarComponent,
    SidebarComponent,
    SidebarAreasNavComponent,
    SidebarNewAssessmentComponent,
    ModuleMaturityComponent,
    ModuleImprovementsComponent,
    RecentAssessmentsComponent,
    AssessmentComponent,
    QuestionComponent,
    ReviewComponent,
    ResultsComponent,
    SidebarResultsNavComponent,
    LoginComponent,
    AdminComponent,
    InviteUserComponent,
    EditUserComponent,
    SidebarInfoComponent,
    MaintenanceModeComponent,
    QuestionPickerComponent,
    PadLevelPipe,
    EditMarketComponent,
    EditRegionComponent,
    EditCategoryComponent,
    EditOrganisationComponent,
    NumberOrBlankPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    HighlightModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    AuthModule.forRoot({
      ...env.auth,
      httpInterceptor: {
        ...env.httpInterceptor,
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: Window,
      useValue: window,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
