import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router'
import { AssessmentsService } from 'src/app/assessments.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-assessment',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.css']
})
export class AssessmentComponent {
  id: string
  assessment_id: number
  questions: object[];
  current_question: object;
  context: object = {};
  all_answered: boolean = false;
  adding_answer: boolean = false;

  hasApiError = false;

  constructor(
    private route: ActivatedRoute,
    private assessments: AssessmentsService,
    private router: Router) {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {
    this.assessments.actions$.subscribe(
      (action) => this.doAction(action)
    );

    this.route.paramMap.subscribe((params: ParamMap) => {
      const a_id = params.get('assessment_id');
      if(a_id) {
        var path = '';

        this.route.queryParams.subscribe(params => {
          path = params.path;
        });

        this.assessmentResume(a_id, path);
      }
    })
  }

  assessmentResume(assessment_id, path) {
    this.assessments.assessmentResume$(assessment_id, path).subscribe({
      next: (res) => {
        this.loadAssessment(res);
      },
      error: () => this.hasApiError = true,
    });
  }

  loadAssessment(res) {
    this.hasApiError = false;
    this.context = res['_context']
    this.questions = res['questions'];
    if(this.questions.length == 0) {
      this.context['q_index'] = -1;
    }
    else {
      this.navigateTo(this.context['q_index']);
    }

    this.assessment_id = this.context['assessment']['id'];

    const progress = this.context['assessment']['progress'];
    this.all_answered = (progress['answer_count'] == progress['question_count'])
    
    this.assessments.addSessionContext(this.context);
    this.assessments.sessionContext$.subscribe(
      (context) => {
        this.context = context;
        this.switchQuestion(this.context['q_index']);
    });

    this.assessments.setSidebar(res['sidebar']);
  }

  answerApi(answer_id) {
    this.adding_answer = true;
    this.assessments.addAnswer$(
      this.context['assessment']['id'],
      this.context['path'],
      this.current_question['q']['id'],
      answer_id
    ).subscribe({
      next: (res) => {
        this.hasApiError = false;

        const context = this.context;
        context['answered'].push(this.current_question['q']['id']);
        context['assessment']['progress'] = res['progress'];

        this.assessments.addSessionContext(context);
        if(res['progress']['question_count'] == res['progress']['answer_count']) {
          this.all_answered = true;
        }
        this.adding_answer = false;
      },
      error: () => {
        this.adding_answer = false;
        this.hasApiError = true;
      }
    });
  }

  switchQuestion(index: number) {
    this.current_question = {
      'q': this.questions[index],
      'i': index,
      'next': this.questions.length > (index+1),
      'prev': index > 0
    };
  }
  
  navigateTo(index: number) {
    this.assessments.addSessionContext({ q_index: index });
  }

  setAnswer(answer_id: number) {
    this.answerApi(answer_id);
    for(const [i, q] of this.questions.entries()) {
      if(q['id'] == this.current_question['q']['id']) {
        this.questions[i]['current_answer'] = answer_id;
        break;
      }
    }
  }

  setComplete() {
    this.assessments.assessmentCompleted$(
      '' + this.assessment_id,
    ).subscribe({
      next: (res) => {
        this.hasApiError = false;
        this.router.navigateByUrl('/results/' + this.context['assessment']['id']);
      },
      error: () => this.hasApiError = true,
    });
  }

  doAction(event: object) {
    switch(event['action']) {
      case 'q_nav':
        this.navigateTo(event['q_index']);
        break;

      case 'complete':
        if(confirm(
          "Once completed, you will no longer be able to make changes to your answers. " +
          "Are you sure that you are ready to complete this assessment?")
        ) {
          this.setComplete();
        }
        break;
    }
  }
}
