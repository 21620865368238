<div *ngIf="hasApiError" class="alert alert-danger" role="alert">
    An error occured when trying to call the assessments endpoint.
</div>
  
<app-question
    [context]="context"
    [question]="current_question"
    [all_answered]="all_answered"
    [adding_answer]="adding_answer"
    (answer)="setAnswer($event)"
/>

<div *ngIf="context">
    <div class="no-questions mt-3" *ngIf="!current_question && context['next_node']">
        <p>
            To begin, choose a question from the menu in the right-hand-column. You can start at the first question and work through the section assessment questions or you can navigate and select a question at any point throughout the assessment. Questions that have been answered are shown with a ‘tick’ icon.        
        </p>

        <a [routerLink]="['/assessment', context['assessment']['id']]"
            [queryParams]="{path: context['next_node']['path'] + '/' + context['next_node']['id']}"
        >
            Continue to section: {{ context['next_node']['label'] }}
        </a>
   
        <div class="my-5">
            <img class="help-graphic" src="../../assets/ow-menu-diagram.svg" />
        </div>
    </div>
</div>