import { Component, Input } from '@angular/core';
import { AssessmentsService } from 'src/app/assessments.service';
import { Chart } from 'chart.js/auto';

@Component({
  selector: 'app-sidebar-areas-nav',
  templateUrl: './sidebar-areas-nav.component.html',
  styleUrls: ['./sidebar-areas-nav.component.css'],
})
export class SidebarAreasNavComponent {
  @Input() data: object;
  @Input() blocked: boolean = false;

  public progressMeter: any = false;
  progress: object = { 'question_count': 0, 'answer_count': 0 };
  context: object = {};

  constructor(
    private assessments: AssessmentsService,
  ) {}

  ngOnInit() {
    this.assessments.sessionContext$.subscribe(
      (context) => {
        this.context = context;
        if('assessment' in context) {
          setTimeout(() => this.updateProgress());
        }
    });
  }

  navMoveToQuestion(index: number) {
    this.assessments.addSessionContext({ q_index: index });
  }

  updateProgress() {
    this.progress = this.context['assessment']['progress'];
    if(!this.progress) {
      return;
    }

    const questions = this.progress['question_count'];
    const answers = this.progress['answer_count'];
    let animation = 1000;

    if(questions) {
      if(this.progressMeter) {
        this.progressMeter.destroy();
        animation = 0; // don't bother animating on updates
      }
  
      this.progressMeter = new Chart('progress-meter', {
        type: 'doughnut',
        data: {
          labels: ['Completed', 'Remaining'],
          datasets: [{
            data: [answers, questions - answers],
            backgroundColor: ['#00ABE2', '#EAEDED'],
          }],
        },
        options: {
          plugins: { legend: { display: false }, tooltip: { enabled: false } },
          circumference: 280,
          rotation: 220,
          cutout: '80%',
          animation: { duration: animation },
        },
      });
    }
  }

  setComplete() {
    this.assessments.pushAction('complete');
  }
}
