<div *ngIf="question && question['q']" class="question mt-3 d-flex">
    <span class="question-code">{{ question['q']['full_code'] }}</span>
    <p class="question-text">{{ question['q']['text'] }}</p>
</div>

<div *ngIf="question && question['q']" class="accordion">
    <table class="answers">
        <tr class="answer" *ngFor="let answer of question['q']['answers']">
            <td class="radio-option">
                <input
                    type="radio" name="answer"
                    value="{{ answer.id }}" id="option-{{ answer.id }}"
                    [checked]="answer.id == question['q']['current_answer']"
                    [disabled]="context['read_only']"
                    (change)="setAnswer($event.target.value)"
                />
            </td>
            <td class="radio-label">
                <label for="option-{{ answer.id }}">{{ answer.level }}</label>
            </td>
            <td class="answer">
                {{ answer.text }}
                
                <div *ngIf="answer['evidence'].length"
                    id="a{{answer.id}}-evidence"
                    class="collapse evidence"
                >
                    <h3>Evidence</h3>
                    <ul>
                        <li *ngFor="let evidence of answer['evidence']">
                            <div *ngIf="evidence.link.label" style="float:right" >
                                <a class="button" [href]="evidence.link.url">
                                    {{ evidence.link.label }}
                                </a>
                            </div>
                            <div>{{ evidence.type }}</div>
                            <div [innerHTML]="evidence.text"></div>
                        </li>
                    </ul>
                </div>
            </td>
            <td class="actions">
                <button *ngIf="answer['evidence'].length"
                    class="accordion-button evidence-button collapsed"
                    data-bs-toggle="collapse"
                    [attr.data-bs-target]="'#a' + answer.id + '-evidence'"
                    aria-expanded="false"
                    [attr.aria-controls]="'a' + answer.id + '-evidence'"
                >
                    Evidence
                </button>
            </td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td>
                <div class="nav-controls d-flex justify-content-between w-100 mt-5">
                    <div class="nav-controls-left-group">
                        <button *ngIf="question['prev']" class="back-button me-2"
                            (click)="navPrevQuestion()"
                        >
                            Back
                        </button>
                        <button *ngIf="!all_answered" class="save-exit me-2" [routerLink]="'/'">
                            Save &amp; Exit
                        </button>
                        <button *ngIf="all_answered" class="save-exit me-2" (click)="setCompleted()">
                            Complete Assessment
                        </button>
                    </div>
                    <button *ngIf="question['next']" class="next-button"
                        [disabled]="adding_answer"
                        (click)="navNextQuestion()"
                    >
                        Next
                    </button>
                    <button *ngIf="!question['next'] && context['next_node']"  class="next-button"
                        [disabled]="adding_answer"
                        [routerLink]="['/assessment', context['assessment']['id']]"
                        [queryParams]="{path: context['next_node']['path'] + '/' + context['next_node']['id']}"
                    >
                        Continue: {{ context['next_node']['label'] }}
                    </button>
                </div>
            </td>
        </tr>
    </table>
</div>