import { Component, Input } from '@angular/core';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { AssessmentsService } from 'src/app/assessments.service';

@Component({
  selector: 'app-recent-assessments',
  templateUrl: './recent-assessments.component.html',
  styleUrls: ['./recent-assessments.component.css']
})
export class RecentAssessmentsComponent {
  @Input() data: any;

  context: object = {}
  faTimesCircle = faTimesCircle

  constructor(private assessments: AssessmentsService) {}

  ngOnInit() {
    this.assessments.sessionContext$.subscribe(
      (context) => (this.context = context)
    );
  }
  
  deleteAssessment(id: number): void {
    if(confirm("Are you sure you want to delete this assessment?")) {
      this.assessments.assessmentDelete$(id.toString()).subscribe({
        next: (res) => {
          this.data = this.data.filter(function (x) { return x.id != id });
      }});
    }
  }
}
