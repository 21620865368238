import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AssessmentsService } from 'src/app/assessments.service';


@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css']
})
export class QuestionComponent {
  @Input() question: object;
  @Input() context: object;
  @Input() all_answered: boolean = false;
  @Input() adding_answer: boolean = false;
  
  @Output() answer: EventEmitter<number> = new EventEmitter<number>();

  constructor(
    private assessments: AssessmentsService,
  ) {}

  navNextQuestion() {
    this.assessments.pushAction('q_nav', {'q_index': this.question['i']+1});
  }

  navPrevQuestion() {
    this.assessments.pushAction('q_nav', {'q_index': this.question['i']-1});
  }

  setAnswer(id) {
    this.answer.emit(id);
  }

  setCompleted() {
    if(confirm(
      "Once completed, you will no longer be able to make changes to your answers. " +
      "Are you sure that you are ready to complete this assessment?")
    ) {
      this.assessments.pushAction('complete');
    }
  }
}
